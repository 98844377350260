import http from '@/utils/http';

const apiList = {
  getMembersList: `/memberInfo/page`,
  deleteMembers: `/memberInfo`,
  getRouter: `/authority/menu/router`,
  loginLog: function (account) {
    return `/authority/loginLog/success/${account}`;
  },
  getTagName: `/account/tag/getTagName`, // 获取所有标签
  memberScoreChangeLog: '/account/memberScoreChangeLog/page', // 积分记录
  dowmloadActivityTpl: '/account/activityAttendeeAction/exportTemplate', // 下载活动数据导入模板
  dowmloadMemberInfoTpl: '/account/memberInfo/exportTemplate', // 下载会员导入模板
};

export default {
  pageUrl: '/account/memberInfo/listPage',
  delete(code, params) {
    return http.delete('/account/memberInfo', { params, code });
  },
  getItemById(code, id) {
    return http.get(`/account/memberInfo/${id}`, { code });
  },
  downloadTExcel(data) {
    return http({
      method: 'GET',
      url: '/account/memberInfo/exportTemplate',
      data: data || {},
    });
  },

  getTagName(data) {
    return http({
      method: 'GET',
      url: apiList.getTagName,
      data: data || {},
    });
  },

  getIntegralRecord(data) {
    return http({
      method: 'GET',
      url: apiList.memberScoreChangeLog,
      data: data || {},
    });
  },

  dowmloadActivityTpl(data) {
    return http({
      method: 'GET',
      url: apiList.dowmloadActivityTpl,
      responseType: 'arraybuffer',
      data: data || {},
    });
  },

  dowmloadMemberInfoTpl(data) {
    return http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: apiList.dowmloadMemberInfoTpl,
      data: data || {},
    });
  },
};
