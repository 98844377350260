<template>
  <a-row :gutter="10">
    <a-col :ms="24" :lg="12" :xl="8" class="mb-10">
      <a-card hoverable class="weith-z">
        <a-card-meta>
          <template #avatar>
            <a-avatar
              v-if="memberInfo.image"
              :src="setAvatar(memberInfo.image)"
              :size="95"
            />

            <!--                <a-avatar v-else :size="95" class="iss-avatar">-->
            <!--                  {{ memberInfo.name }}-->
            <!--                </a-avatar>-->
          </template>
          <template #description class="over-height">
            <a-descriptions :column="1">
              <a-descriptions-item label="姓名">
                {{ memberInfo.name }}
              </a-descriptions-item>
              <a-descriptions-item label="国家">
                {{ memberInfo.country }}
              </a-descriptions-item>
              <a-descriptions-item label="公司">
                {{ memberInfo.company }}
              </a-descriptions-item>
              <a-descriptions-item label="职位">
                {{ memberInfo.jobTitle }}
              </a-descriptions-item>
              <a-descriptions-item label="客户类别">
                {{ memberInfo.customerType }}
              </a-descriptions-item>
              <a-descriptions-item label="电话">
                {{ memberInfo.phone }}
              </a-descriptions-item>
              <a-descriptions-item label="邮箱">
                {{ memberInfo.email }}
              </a-descriptions-item>
            </a-descriptions>
          </template>
        </a-card-meta>
      </a-card>
    </a-col>
    <a-col :ms="24" :lg="12" :xl="8" class="mb-10">
      <a-card hoverable class="over-height">
        <a-card-meta>
          <template #avatar>
            <div id="maturity" />
          </template>
          <template #description>
            <a-descriptions :column="1">
              <a-descriptions-item label="成熟度"> 55 </a-descriptions-item>
              <a-descriptions-item label="平均成熟度"> 5% </a-descriptions-item>
              <a-descriptions-item label="活跃等级"> </a-descriptions-item>
              <a-descriptions-item label="客户属性">
                {{ maturityInfo.property }}
              </a-descriptions-item>
              <a-descriptions-item label="客户需求">
                {{ maturityInfo.need }}
              </a-descriptions-item>
              <a-descriptions-item label="客户预算">
                {{ maturityInfo.budget }}
              </a-descriptions-item>
            </a-descriptions>
          </template>
        </a-card-meta>
      </a-card>
    </a-col>
    <a-col :ms="24" :lg="12" :xl="8" class="mb-10">
      <a-card hoverable class="over-height, y-margins">
        <a-card-meta>
          <template #avatar>
            <div id="activity" />
          </template>
          <template #description>
            <a-descriptions :column="1">
              <a-descriptions-item label="活跃度"> 55 </a-descriptions-item>
              <a-descriptions-item label="平均活跃度"> 5% </a-descriptions-item>
              <a-descriptions-item label="签到次数"> </a-descriptions-item>
              <a-descriptions-item label="最近一次参会时间">
                {{ maturityInfo.property }}
              </a-descriptions-item>
              <a-descriptions-item label="互动次数">
                {{ maturityInfo.need }}
              </a-descriptions-item>
              <a-descriptions-item label="参与问卷次数">
                {{ maturityInfo.budget }}
              </a-descriptions-item>
              <a-descriptions-item label="积分兑换频率">
                {{ maturityInfo.budget }}
              </a-descriptions-item>
            </a-descriptions>
          </template>
        </a-card-meta>
      </a-card>
    </a-col>
  </a-row>
  <a-row>
    <a-col :span="24" class="pl-24 pr-24">
      <a-card hoverable title="成熟度趋势">
        <template #extra>
          <a-range-picker></a-range-picker>
        </template>
      </a-card>
    </a-col>
  </a-row>
  <a-row>
    <a-col :span="24" class="pl-24 pr-24">
      <a-card hoverable title="活跃度趋势">
        <template #extra>
          <a-range-picker></a-range-picker>
        </template>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import {
  Avatar,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Row,
} from 'ant-design-vue';
import { Chart } from '@antv/g2';
import { setAvatar } from '@/utils';
import memberApi from '@/api/member';
import portraitApi from '@/api/portrait';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ACardMeta: Card.Meta,
    AAvatar: Avatar,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ARangePicker: DatePicker.RangePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      memberInfo: {},
      maturityInfo: {},
      activityInfo: {},
    };
  },
  created() {
    this.initMemberInfo();
  },
  mounted() {
    this.initDashboard('maturity', 55, '成熟度');
    this.initDashboard('activity', 55, '活跃度');
  },
  methods: {
    initMemberInfo() {
      memberApi.getItemById('portrait:view', this.id).then(data => {
        this.memberInfo = data;
        this.initMaturityInfo();
        this.initActivityInfo();
      });
    },
    initMaturityInfo() {
      portraitApi
        .getMaturity('portrait:view', {
          ismartekId: this.memberInfo.ismartekId,
        })
        .then(data => {
          this.maturityInfo = data;
        });
    },
    initActivityInfo() {
      portraitApi
        .getActivity('portrait:view', {
          ismartekId: this.memberInfo.ismartekId,
        })
        .then(data => {
          this.activityInfo = data;
        });
    },
    initDashboard(container, value, title) {
      const data1 = [];
      for (let i = 0; i <= 50; i++) {
        data1.push({
          type: i * 2 + '',
          value: 10,
        });
      }

      const data2 = [];
      let temp = parseInt(value / 2);
      for (let i = 0; i <= 50; i++) {
        const item = {};
        item.type = i + '';
        item.value = 10;
        if (i === temp) {
          item.value = 14;
        }
        if (i > temp) {
          item.value = 0;
        }
        data2.push(item);
      }

      const chart = new Chart({
        container,
        autoFit: true,
        width: 150,
        height: 130,
        padding: [-30, 0, 0, 0],
      });
      chart.scale({
        type: {
          range: [0, 1],
        },
        value: {
          sync: true,
        },
      });
      chart.legend(false);

      const view1 = chart.createView();
      view1.data(data1);
      view1.axis(false);
      view1.coordinate('polar', {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      });
      view1.interval().position('type*value').color('#CBCBCB').size(4);

      // const view2 = chart.createView();
      // view2.data(data1);
      // view2.axis('value', false);
      // view2.axis('type', {
      //   grid: null,
      //   line: null,
      //   tickLine: null,
      //   label: {
      //     offset: -12,
      //     style: {
      //       textAlign: 'center',
      //       fill: '#CBCBCB',
      //     },
      //     // formatter: val => (+val % 10 === 0 ? val : ''),
      //   },
      // });
      // view2.coordinate('polar', {
      //   startAngle: (-9 / 8) * Math.PI,
      //   endAngle: (1 / 8) * Math.PI,
      //   innerRadius: 0.95,
      //   radius: 0.55,
      // });
      // view2.interval().position('type*value').color('#CBCBCB').size(6);

      const view3 = chart.createView();
      view3.data(data2);
      view3.axis(false);
      view3.coordinate('polar', {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      });
      view3
        .interval()
        .position('type*value')
        .color('value', '#3023AE-#53A0FD')
        .size(4);

      view3.annotation().text({
        position: ['50%', '65%'],
        content: `${value}分`,
        style: {
          fill: '#5979f8',
          fontSize: 20,
          textAlign: 'center',
          textBaseline: 'middle',
        },
      });

      view3.annotation().text({
        position: ['49%', '95%'],
        content: title,
        style: {
          fill: '#424242',
          fontSize: 16,
          textAlign: 'center',
          textBaseline: 'middle',
        },
      });
      chart.axis(false);
      chart.render();
    },
    setAvatar,
  },
};
</script>
<style lang="less" scoped>
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ant-card {
  margin-left: 0px;
  margin-top: 15px;
}
.ant-col-xl-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 32%;
}
.ant-card-meta[data-v-67aad984] {
  margin: 10px 0;
  zoom: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
}
.ant-descriptions-title {
  text-align: center;
}
.over-height {
  height: 390px;
  overflow: auto;
}
.weith-z {
  height: 390px;
  overflow: auto;
  margin-left: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 71, 186, 0.2);
}
.ant-descriptions {
  font-size: 14px;
  font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
  font-weight: normal;
  color: #313d5f;
  line-height: 20px;
}
.y-margins {
  height: 390px;
  overflow: auto;
  margin-right: -24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 71, 186, 0.2);
}
.z-margins {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 71, 186, 0.2);
}
.ant-card[data-v-67aad984] {
  border-radius: 8px;
}
</style>
